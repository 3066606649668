import "../Styles/users.scss";
import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import Input from "../components/Users/UsrInput";
import Button from "../components/Utils/ButtonMain";
import Select from "../components/Users/UsrSelect";
import { useRef, useState } from "react";
import Loading from "../components/Utils/Loading";
import { useEffect } from "react";

export default function PrivateUsersCreatePage(props) {
  const [isErr, setIsErr] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const roles = ["Regular", "Admin", "Super Admin", "Guest"];
  const rfEmail = useRef();
  const rfName = useRef();
  const rfPhone = useRef();
  const rfPosition = useRef();
  const rfDepartment = useRef();
  const rfRole = useRef();
  const rfPassword = useRef();
  const rfCPassword = useRef();
  const [body, updateBody] = useState({
    Email: null,
    Name: null,
    Phone: null,
    Position: null,
    Department: null,
    Password: null,
    Role: roles[0],
  });
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const validateForm = () => {
    let result = true;
    let d = body;
    d.Email = rfEmail.current.value.toLowerCase().trim();
    d.Name = rfName.current.value;
    d.Phone = rfPhone.current.value;
    d.Position = rfPosition.current.value;
    d.Department = rfDepartment.current.value;
    d.Role = rfRole.current.value;
    d.Password = rfPassword.current.value;

    updateBody(d);
    setIsErr("");

    if (body.Password.length < 6) {
      result = false;
      setIsErr("Password is too short");
      return result;
    }

    if (body.Password !== rfCPassword.current.value) {
      result = false;
      setIsErr("Passwords do not match!");
      return result;
    }

    if (!body.Name || body.Name.split(" ").length < 2) {
      result = false;
      setIsErr("Enter at least 2 names!");
      return result;
    } else {
      let d = body;
      d.Name = rfName.current.value
        .split(" ")
        .map((w) => w[0]?.toUpperCase() + w.substring(1).toLowerCase())
        .join(" ");
      updateBody(d);
    }
    if (!validateEmail(body.Email)) {
      result = false;
      setIsErr("Enter a valid email address!");
      return result;
    }
    if (!body.Phone || body.Phone.length !== 12) {
      result = false;
      setIsErr("Enter a valid phone number! (+25470123123)");
      return result;
    }
    if (!body.Position) {
      result = false;
      setIsErr("Enter a valid position!");
      return result;
    }
    if (!body.Department) {
      result = false;
      setIsErr("Enter a valid department!");
      return result;
    }

    return result;
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const createUser = () => {
    if (validateForm()) {
      setIsLoading(true);
      fetch("/api/auth/register", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.success) {
            setIsErr(data.success);
            rfEmail.current.value = "";
            rfName.current.value = "";
            rfPhone.current.value = "";
            rfPosition.current.value = "";
            rfRole.current.value = "";
            rfCPassword.current.value = "";
            rfPassword.current.value = "";
            setTimeout(() => {
              window.location.href = "/portal/users";
            }, 1000);
          } else if (data.error) {
            setIsErr(data.error);
          } else setIsErr("Oops! Something went wrong!");
        })
        .catch((err) => {
          setIsLoading(false);
          setIsErr("Oops! Something went wrong!");
        });
    }
  };

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Create User"
      />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Create User"
        />
        <div className="users">
          <div className="list">
            <h3>New Portal User</h3>
            <hr />
            <div className="new">
              <form
                autoComplete="none"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <span className="err">{isErr}</span>
                <Input ref={rfName} type="text" label="Full Name *" />
                <div className="div2equal">
                  <Input ref={rfEmail} type="email" label="Email Address *" />
                  <Input ref={rfPhone} type="number" label="Phone Number *" />
                </div>
                <div className="div2equal">
                  <Input ref={rfPosition} type="text" label="Position *" />
                  <Input ref={rfDepartment} type="text" label="Department *" />
                </div>

                <Select ref={rfRole} label="Select Role" data={roles} />

                <div className="div2equal">
                  <Input ref={rfPassword} type="password" label="Password *" />
                  <Input
                    ref={rfCPassword}
                    type="password"
                    label="Confirm Password *"
                  />
                </div>
                <button
                  onClick={() => {
                    createUser();
                  }}
                >
                  Submit
                </button>
              </form>
              {isLoading && <Loading />}
            </div>
          </div>
        </div>
        ;
      </div>
    </div>
  );
}
