import React, { useRef, useState } from "react";
import Button from "../Utils/ButtonMain";

export default function ReplyMessage(props) {
  const [isError, setIsError] = useState("");
  const [body, updateBody] = useState({
    MessageID: null,
    UserID: null,
    UserID: null,
    Content: null,
  });
  const [isloading, setIsLoading] = useState(false);
  const rfContent = useRef();

  const sendReply = () => {
    let d = body;
    d.MessageID = props.messageID;
    d.CommentID = props.messageID;
    d.UserID = props.currentUser?.Name;
    d.UserRole = props.currentUser?.Role;
    d.Content = rfContent.current.value;
    updateBody(d);
    setIsError("");
    if (!body.Content) return setIsError("Please write a reply!");

    if (body.Content) {
      setIsLoading(true);
      if (props.target === "messages") {
        fetch(`/api/replies/reply`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else throw Error("Could not Send Reply!!");
          })
          .then((data) => {
            setIsLoading(false);
            if (data.success) {
              setIsError(data.success);
              setTimeout(() => {
                props.setRefreshReplies(!props.refreshReplies);
                props.setReplyMessage(false);
              }, 1000);
            } else {
              setIsError(data.error);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            setIsError("Could not Send Reply!");
          });
      }
      if (props.target === "comments") {
        fetch(`/api/createcommentsreply/reply`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else throw Error("Could not Send Reply!!");
          })
          .then((data) => {
            setIsLoading(false);
            if (data.success) {
              setIsError(data.success);
              setTimeout(() => {
                props.setRefreshReplies(!props.refreshReplies);
                props.setReplyMessage(false);
              }, 1000);
            } else {
              setIsError(data.error);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            setIsError("Could not Send Reply!");
          });
      }
    }
  };

  return (
    <div className="mypopup">
      <div className="container">
        <i
          onClick={() => {
            props.setReplyMessage(false);
          }}
          className="fa fa-times"
        ></i>
        <h3>
          {props.target === "messages"
            ? "Reply to Message"
            : "Reply to Comment"}
        </h3>
        <p>{isError}</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="input-map">
            <h4>Reply</h4>
            <textarea
              ref={rfContent}
              name=""
              id=""
              cols="30"
              rows="5"
            ></textarea>
          </div>
          <button
            onClick={() => {
              sendReply();
            }}
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
}
