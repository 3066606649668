import React, { useRef, useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Paper,
  Card,
  Divider,
} from "@mui/material";
import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";

export default function PublicationsCreatePage() {
  const [isErr, setIsErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const types = ["Publications", "Reports", "Learning Materials", "Other"];
  const rfTitle = useRef();
  const rfType = useRef();
  const rfDescription = useRef();
  const rfKeywords = useRef();
  const rfDate = useRef();
  const rfFile = useRef();
  const [body, updateBody] = useState({
    Title: null,
    Type: null,
    Description: null,
    Keywords: null,
    Date: null,
    File: null,
  });
  const [showing, setShowing] = useState(true);

  const handleResize = () => {
    setShowing(window.innerWidth >= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const validateForm = () => {
    let result = true;
    let d = {
      Title: rfTitle.current.value,
      Type: rfType.current.value,
      Description: rfDescription.current.value,
      Keywords: rfKeywords.current.value,
      Date: rfDate.current.value,
      File: rfFile.current.files[0],
    };

    updateBody(d);
    setIsErr("");

    if (!d.Title || !d.Type) {
      result = false;
      setIsErr("Title and Type Fields cannot be empty!");
    } else if (!d.Description || d.Description.length < 20) {
      result = false;
      setIsErr("Enter a sufficient description!");
    } else if (!d.File) {
      result = false;
      setIsErr("Upload a file to publish!");
    } else if (!d.Date) {
      result = false;
      setIsErr("Enter a valid date of the publication!");
    }

    return result;
  };

  const createPublication = async () => {
    if (validateForm()) {
      setIsLoading(true);
      const formData = new FormData();
      Object.entries(body).forEach(([key, value]) => {
        formData.append(key, value);
      });

      try {
        const response = await fetch("/api/publications/create", {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            setIsErr(data.success);
            setTimeout(() => {
              window.location.href = "/portal/knowledgehub";
            }, 1000);
          } else {
            setIsErr(data.error);
          }
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        setIsErr("Oops! Something went wrong!");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Add a Publication"
      />
      <div component="main">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Create Publication"
        />
        <Container sx={{ overflowY: "auto", height: "90vh" }}>
          <Card sx={{ p: 3, my: 3 }}>
            <Typography variant="h5">New Document</Typography>
            <Divider sx={{ marginTop: 3 }} />
            <Box sx={{ my: 2 }}>
              {isErr && <Typography color="error">{isErr}</Typography>}
              <form
                noValidate
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  createPublication();
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <TextField
                    inputRef={rfTitle}
                    label="Title *"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                  />
                  <TextField
                    select
                    inputRef={rfType}
                    label="Select Type"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  >
                    {types.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <TextField
                    inputRef={rfDescription}
                    label="Description *"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                  />
                </Box>

                <Box sx={{ mb: 2 }}>
                  <TextField
                    inputRef={rfKeywords}
                    label="Keywords *"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                  />
                  <TextField
                    inputRef={rfDate}
                    label="Date *"
                    type="date"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>

                <Box sx={{ mb: 2 }}>
                  <TextField
                    type="file"
                    label="File *"
                    inputRef={rfFile}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    inputProps={{
                      accept: ".pdf",
                    }}
                    sx={{ marginTop: "8px" }}
                  />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={createPublication}
                >
                  Submit
                </Button>
              </form>
              {isLoading && <CircularProgress sx={{ mt: 2 }} />}
            </Box>
          </Card>
        </Container>
      </div>
    </div>
  );
}
